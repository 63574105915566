'use client'; // Error components must be Client Components

import Image from 'next/image';
import { useEffect } from 'react';
import Link from 'next/link';
import page_not_found from '../../public/assets/error/page_not_found.png';
import { Button } from '@/components/ui/button';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    // <div>
    //   <h2>Something went wrong!</h2>
    //   <button
    //     onClick={
    //       // Attempt to recover by trying to re-render the segment
    //       () => reset()
    //     }
    //   >
    //     Try again
    //   </button>
    // </div>
    <div className="flex flex-col items-center justify-center min-h-[100dvh] bg-white dark:bg-gray-950 text-slate-900 dark:text-gray-50 px-4">
      <div className="max-w-md w-full space-y-6 text-center">
        <Image
          src={page_not_found}
          width={420}
          height={300}
          alt="404 Illustration"
          className="mx-auto  h-full -mt-10  rounded-lg object-fit"
        />
        <div className="">
          <h1 className="text-4xl -mt-10 font-bold">Something went wrong!</h1>
          <p className="text-gray-500">
            Sorry, something went wrong! Please try again or go back to the
            homepage.
          </p>
        </div>
        <div className='flex items-center justify-center flex-wrap md:gap-10'>
          <Link href="/" className="w-full md:w-fit">
            <Button
              variant="default"
              size="lg"
              className="group w-full mt-6 md:w-fit"
            >
              <svg
                className="ml-2 mr-2 w-5 h-5 rotate-180 group-hover:-translate-x-1 transition"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Go back home
            </Button>
          </Link>
          <Button
            variant="outline"
            size="lg"
            className="group w-full mt-6 md:w-fit flex items-center gap-4 "
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
          
            Try again
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-rotate-cw group-hover:rotate-180 transition"
            >
              <path d="M21 12a9 9 0 1 1-9-9c2.52 0 4.93 1 6.74 2.74L21 8" />
              <path d="M21 3v5h-5" />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
}
